<template>
    <div class="submit-wrapper">
        <h1 class="title">{{ $t('order.individualOrder') }}</h1>
        <PreviewIndividualOrder
            v-if="allSelectedAdditionsState"
            :order-state="orderState"
            :all-selected-additions-state="allSelectedAdditionsState"
            :delivery-data="deliveryInformation?.deliveryData"
            :address-data="buyerInformation?.addressData"
            :city-name="buyerInformation?.addressData.city.name"
            :orderable-item="orderState.orderableItem"
            with-subtitle
        />
        <!-- Buttons -->
        <div class="btn-section">
            <CRButton pattern="outline" @click="$router.push('buyer-info')">
                <ArrowSvg class="arrow-icon" />
                {{ $t('buttons.goBack') }}
            </CRButton>
            <CRButton @click="submitTheOrder" :loading="isLoading">
                {{ $t('order.submit') }}
            </CRButton>
        </div>

        <SuccessModal @close="closeSuccessModal" v-if="isSuccessModalOpened" :title="$t('order.orderRequestPlaced')">
            <CashModalContent is-check-out-free />
        </SuccessModal>
    </div>
</template>

<script>
    import SuccessModal from '@/components/Modals/SuccessModal';
    import CashModalContent from '@/components/Order/ModalContent/CashModalContent.vue';
    import PreviewIndividualOrder from '@/components/Order/Individual/PreviewIndividualOrder.vue';
    import ArrowSvg from '@/assets/icons/arrows/arrow-down.svg?inline';
    import CRButton from '@/components/Common/Buttons/CRButton';
    import { IndividualOrderApi } from '@/api';
    import { mapGetters } from 'vuex';
    import { IndividualOrderableEnum } from '@/utils/enums';
    import { TYPE } from 'vue-toastification';

    export default {
        name: 'IndividualOrderSubmit',
        components: { CRButton, PreviewIndividualOrder, ArrowSvg, SuccessModal, CashModalContent },
        data() {
            return {
                IndividualOrderableEnum,

                isLoading: false,
                isSuccessModalOpened: false,
            };
        },
        computed: {
            ...mapGetters('auth', ['user']),
            ...mapGetters('mealKit', ['individualOrderSettings']),
            ...mapGetters('orderInformation', ['deliveryInformation', 'buyerInformation']),
            ...mapGetters('individualOrder', ['orderState', 'personalMenuState', 'allSelectedAdditionsState']),

            isMacronutrientsPresent() {
                return (
                    this.individualOrderSettings?.withMacronutrients &&
                    (this.orderState.macronutrients?.fats ||
                        this.orderState.macronutrients?.proteins ||
                        this.orderState.macronutrients?.carbohydrates ||
                        this.orderState.macronutrients?.comment)
                );
            },

            isMealNumberPresent() {
                return (
                    this.individualOrderSettings?.withMealNumber &&
                    (this.orderState.mealNumber?.comment || this.orderState.mealNumber?.amount)
                );
            },

            isDietPlansPresent() {
                return (
                    this.individualOrderSettings?.withDietPlans &&
                    (this.orderState.additionalDietPlan?.name || this.orderState.additionalDietPlan?.comment)
                );
            },
        },
        methods: {
            collectDataForSend() {
                const order = {
                    periodId: this.orderState.period.id,
                    menuTypeId: this.orderState.menuType.id,
                    caloriesAmount: this.orderState.caloriesAmount,
                    kitsAmount: this.orderState.kitsAmount,
                    additionalAllergen: this.orderState.additionalAllergen,
                    additionalDislikedProduct: this.orderState.additionalDislikedProduct,
                    otherRequest: this.orderState.otherRequest,
                    orderableType: this.orderState.orderableType,
                    orderableId: this.orderState.orderableItem.id,
                    macronutrients: this.isMacronutrientsPresent ? this.orderState.macronutrients : null,
                    additionalDietPlan: this.isDietPlansPresent ? this.orderState.additionalDietPlan : null,
                    mealNumber: this.isMealNumberPresent ? this.orderState.mealNumber : null,
                };

                const allergensIds = this.getIdsArray('allergens');
                const additionsIds = this.getIdsArray('additions');
                const dislikedProductsIds = this.getIdsArray('dislikedProducts');
                const dietPlansIds = this.getIdsArray('dietPlans');

                // User
                const userData = this.buyerInformation.userData;
                userData.userId = this.user ? this.user.id : null;

                // Delivery Address
                const deliveryAddress = this.buyerInformation.addressData;
                deliveryAddress.city_id = this.buyerInformation.cityData.id;
                delete deliveryAddress.name;

                return {
                    order,
                    allergensIds,
                    additionsIds,
                    dislikedProductsIds,
                    dietPlansIds,
                    userData,
                    deliveryInformation: this.deliveryInformation,
                    deliveryAddress,
                };
            },

            getIdsArray(field) {
                return this.allSelectedAdditionsState
                    ? this.allSelectedAdditionsState[field].map((item) => item.id)
                    : [];
            },

            closeSuccessModal() {
                this.$filters.toast(this.$t('individualOrder.submitted'), { type: TYPE.SUCCESS });

                this.$router.push({ name: 'main' });
            },

            async submitTheOrder() {
                try {
                    this.isLoading = true;

                    await IndividualOrderApi.store(this.collectDataForSend());

                    this.isSuccessModalOpened = true;
                } catch (error) {
                    Object.entries(error.errors).forEach(async (element) => {
                        this.$filters.toast(element[1][0]);
                    });
                } finally {
                    this.isLoading = false;
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .submit-wrapper {
        @include column-align-center;

        padding-bottom: 25px;
        width: 100%;

        @include media($lg) {
            margin: 0 auto;
            max-width: 576px;
        }

        .title {
            margin-bottom: 32px;
        }

        .btn-section {
            @include row-align-center-justify-end;

            width: 100%;
            margin-top: 20px;

            :first-child {
                margin-right: 10px;
            }

            .arrow-icon {
                transform: rotate(90deg);
            }
        }
    }
</style>
