<template>
    <div class="submit-wrapper__inner">
        <h2 class="mb-10">{{ $t('common.yourOrder') }}</h2>
        <h5 v-if="withSubtitle" class="subtitle">{{ $t('individualOrder.submitHelpText') }}</h5>
        <div class="submit-item__inner-items">
            <div class="item-row">
                <span> {{ $t('cart.kitType') }} </span>
                <CRTag type="additional"> {{ $t(`common.${orderState?.menuType.type}`) }} </CRTag>
            </div>
            <div class="item-row" v-if="orderState?.orderableType === IndividualOrderableEnum.MEAL_KIT">
                <span> {{ $t('order.kit') }} </span>
                <CRTag type="additional"> {{ $filters.getTranslationByKey(orderableItem.name) }} </CRTag>
            </div>
            <div class="item-row" v-else>
                <div class="tag__wrapper" v-if="orderableItem">
                    <span> {{ $t('order.personalMenu') }} </span>
                    <CRTag
                        v-if="orderableItem.filePath"
                        class="tag"
                        svgColor="#00595A"
                        type="additional"
                        icon="document-filled"
                    >
                        <a :href="orderableItem.filePath" target="_blank" class="underscored-link">
                            {{ $t('individualOrder.menu') }}
                        </a>
                    </CRTag>
                    <CRTag class="tag" svgColor="#00595A" type="additional" icon="web" v-if="orderableItem.link">
                        <a :href="orderableItem.link" target="_blank" class="underscored-link">
                            {{ orderableItem.link }}
                        </a>
                    </CRTag>
                    <CRTag class="tag" type="additional" v-if="orderableItem.extraInformation">
                        {{ orderableItem.extraInformation }}
                    </CRTag>
                </div>
            </div>
            <div class="item-row" v-if="orderState?.caloriesAmount">
                <span> {{ $t('individualOrder.caloriesAmount') }} </span>
                <CRTag type="additional"> {{ orderState?.caloriesAmount }}kcal </CRTag>
            </div>
        </div>

        <h2 v-if="isIndividualRequestsTitleVisible" class="mb-10">
            {{ $t('order.individualRequests') }}
        </h2>

        <!-- Allergens -->
        <div class="mb-20" v-if="isAllergensVisible">
            <h3 class="mb-10">{{ $t('individualOrder.allergens') }}</h3>
            <div class="submit-item__inner-items__tagged">
                <CRTag
                    v-for="allergen in allSelectedAdditionsState.allergens"
                    :key="allergen.id"
                    class="tag"
                    type="additional"
                >
                    {{ $filters.getTranslationByKey(allergen.name) }}
                </CRTag>
                <CRTag v-if="orderState.additionalAllergen" class="tag" type="additional">
                    {{ orderState.additionalAllergen }}
                </CRTag>
            </div>
        </div>

        <!-- Disliked products -->
        <div class="mb-20" v-if="isDislikedProductsVisible">
            <h3 class="mb-10">{{ $t('individualOrder.dislikedProducts') }}</h3>
            <div class="submit-item__inner-items__tagged">
                <CRTag
                    v-for="dislikedProduct in allSelectedAdditionsState.dislikedProducts"
                    :key="dislikedProduct.id"
                    type="additional"
                    class="tag"
                >
                    {{ $filters.getTranslationByKey(dislikedProduct.name) }}
                </CRTag>
                <CRTag v-if="orderState.additionalDislikedProduct" class="tag" type="additional">
                    {{ orderState.additionalDislikedProduct }}
                </CRTag>
            </div>
        </div>

        <!-- Macronutrients -->
        <div class="mb-20" v-if="isMacronutrientsVisible">
            <div class="submit-item__inner">
                <h3 class="mb-10">{{ $t('individualOrder.macronutrients') }}</h3>
                <div class="submit-item__inner-items__tagged">
                    <div v-for="(item, index) in Object.entries(orderState.macronutrients)" :key="index">
                        <CRTag v-if="item[1]" class="tag" type="additional">
                            {{ $t(`dishes.${item[0]}`, { amount: item[1] }) }}
                        </CRTag>
                    </div>
                </div>
            </div>
        </div>

        <!-- Diet plans -->
        <div class="mb-20" v-if="isDietPlansVisible">
            <div class="submit-item__inner">
                <h3 class="mb-10">{{ $t('individualOrder.dietPlans') }}</h3>
                <div class="submit-item__inner-items__tagged">
                    <CRTag
                        v-for="dietPlan in allSelectedAdditionsState.dietPlans"
                        :key="dietPlan.id"
                        class="tag"
                        type="additional"
                    >
                        {{ $filters.getTranslationByKey(dietPlan.name) }}
                    </CRTag>
                    <CRTag v-if="orderState.additionalDietPlan.name" class="tag" type="additional">
                        {{ orderState.additionalDietPlan.name }}
                    </CRTag>
                    <CRTag v-if="orderState.additionalDietPlan.comment" class="tag" type="additional">
                        {{ orderState.additionalDietPlan.comment }}
                    </CRTag>
                </div>
            </div>
        </div>

        <!-- Meal number -->
        <div class="mb-20" v-if="isMealNumberVisible">
            <h3 class="mb-10">{{ $t('order.specificDishNumber') }}</h3>
            <div class="submit-item__inner-items">
                <div v-if="orderState.mealNumber.amount" class="item-row">
                    <span>{{ $t('individualOrder.dishNumber') }}</span>
                    <CRTag type="additional">{{ orderState.mealNumber.amount }}</CRTag>
                </div>
                <div v-if="orderState.mealNumber.comment" class="item-row">
                    <span>{{ $t('order.comments') }}</span>
                    <CRTag class="comment-tag" type="additional">{{ orderState.mealNumber.comment }}</CRTag>
                </div>
            </div>
        </div>

        <!-- Other requests -->
        <div class="mb-20" v-if="isOtherRequestVisible">
            <div class="submit-item__inner">
                <h3 class="mb-10">{{ $t('individualOrder.otherRequests') }}</h3>
                <div class="submit-item__inner-items__tagged">
                    <CRTag
                        v-for="addition in allSelectedAdditionsState.additions"
                        :key="addition.id"
                        class="tag"
                        type="additional"
                    >
                        {{ $filters.getTranslationByKey(addition.name) }}
                    </CRTag>
                    <CRTag v-if="orderState.otherRequest" class="tag" type="additional">
                        {{ orderState.otherRequest }}
                    </CRTag>
                </div>
            </div>
        </div>

        <!-- Delivery Information -->
        <div class="mb-20">
            <h3 class="mb-10">{{ $t('order.deliveryInformation') }}</h3>
            <div class="submit-item__inner-items">
                <div class="item-row">
                    <span> {{ $t('order.numberOfKits') }} </span>
                    <CRTag type="additional"> {{ orderState?.kitsAmount }} </CRTag>
                </div>
                <div class="item-row">
                    <span> {{ $t('individualOrder.orderDuration') }} </span>
                    <CRTag type="additional">
                        {{ $filters.getTranslationByKey(orderState?.period.name) }}
                    </CRTag>
                </div>
                <div class="item-row">
                    <span> {{ $t('common.deliveryDate') }} </span>
                    <CRTag type="additional">
                        {{ deliveryData.deliveryStartDate }}
                    </CRTag>
                </div>
                <div class="item-row">
                    <span> {{ $t('common.deliveryTime') }} </span>
                    <CRTag type="additional">{{ deliveryData.startTime }} - {{ deliveryData.endTime }}</CRTag>
                </div>
            </div>
        </div>

        <!-- Delivery Address -->
        <div class="mb-0">
            <h3 class="mb-10">{{ $t('buyerInfo.deliveryAddress') }}</h3>
            <div class="submit-item__inner-items">
                <div class="item-row">
                    <span> {{ $t('individualOrder.city') }} </span>
                    <CRTag type="additional">{{ cityName }}</CRTag>
                </div>
                <div class="item-row">
                    <span> {{ $t('common.address') }} </span>
                    <CRTag type="additional">{{ addressLabel }}</CRTag>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CRTag from '@/components/Common/Tags/CRTag';
    import { IndividualOrderableEnum } from '@/utils/enums';

    export default {
        name: 'PreviewIndividualOrder',
        components: { CRTag },
        props: {
            withSubtitle: {
                type: Boolean,
                default: false,
            },
            orderableItem: {
                type: Object,
                default: () => {},
            },
            orderState: {
                type: Object,
                default: () => {},
            },
            allSelectedAdditionsState: {
                type: Object,
                default: () => {},
            },
            deliveryData: {
                type: Object,
                default: () => {},
            },
            addressData: {
                type: Object,
                default: () => {},
            },
            cityName: {
                type: String,
                default: '',
            },
        },
        data() {
            return {
                IndividualOrderableEnum,
            };
        },
        computed: {
            isDietPlansVisible() {
                return (
                    this.allSelectedAdditionsState?.dietPlans.length ||
                    this.orderState.additionalDietPlan?.name ||
                    this.orderState.additionalDietPlan?.comment
                );
            },

            isAllergensVisible() {
                return this.allSelectedAdditionsState?.allergens.length || this.orderState?.additionalAllergen;
            },

            isMealNumberVisible() {
                return this.orderState.mealNumber?.comment || this.orderState.mealNumber?.amount;
            },

            isDislikedProductsVisible() {
                return (
                    this.allSelectedAdditionsState?.dislikedProducts.length ||
                    this.orderState?.additionalDislikedProduct
                );
            },

            isOtherRequestVisible() {
                return this.allSelectedAdditionsState?.additions.length || this.orderState?.otherRequest;
            },

            isMacronutrientsVisible() {
                return (
                    this.orderState.macronutrients?.fats ||
                    this.orderState.macronutrients?.proteins ||
                    this.orderState.macronutrients?.carbohydrates ||
                    this.orderState.macronutrients?.comment
                );
            },

            isIndividualRequestsTitleVisible() {
                return (
                    this.isDietPlansVisible ||
                    this.isAllergensVisible ||
                    this.isMealNumberVisible ||
                    this.isDislikedProductsVisible ||
                    this.isOtherRequestVisible ||
                    this.isMacronutrientsVisible
                );
            },

            addressLabel() {
                return `${this.addressData?.street}, ${this.addressData?.houseNumber}, ${this.addressData?.postalCode}`;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .subtitle {
        color: $grey-form-label;
        margin-bottom: 10px;
    }

    .submit-item__inner-items__tagged {
        @include row-align-center;
        flex-wrap: wrap;

        box-shadow: 0 0 3px rgba(0, 0, 0, 0.35);

        padding: 10px 10px 0 10px;
        border-radius: $br-10;

        .tag {
            margin-right: 10px;
            margin-bottom: 10px;
        }
    }

    .submit-wrapper__inner {
        width: 100%;

        .submit-item__inner {
            margin-bottom: 20px;

            &-items {
                box-shadow: 0 0 3px rgba(0, 0, 0, 0.35);

                padding: 10px;
                border-radius: $br-10;

                margin-bottom: 10px;
            }
        }

        .item-row {
            @include row-align-start;
            flex-wrap: wrap;

            margin-bottom: 10px;

            &:last-of-type {
                margin-bottom: 0;
            }

            span {
                margin-right: 8px;
            }

            .comment-tag {
                max-width: 200px;
            }
        }
    }

    .tag__wrapper {
        @include row-align-start;
        flex-wrap: wrap;

        .tag {
            max-width: 100%;
            margin-right: 10px;

            overflow: hidden;

            &:deep(.tag-container__text) {
                width: 100%;

                text-overflow: ellipsis;
                overflow: hidden;
            }

            .underscored-link {
                text-decoration: underline;
            }
        }
    }
</style>
